  <footer class="page-footer">
    <div class="footer-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p class="mb-0 text-muted">All Right Reserved @ 2023 Made with <3 by <a target=”_blank” href="https://www.syabe-tech.com">SYABE TECHNOLOGIES</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
