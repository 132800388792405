let codeUser: number;
// tslint:disable-next-line:radix
// console.log(parseInt(JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'))[0].code));
// tslint:disable-next-line:radix
// console.log(localStorage.getItem('USER_CONNECTED_INFO'));
if (localStorage.getItem('USER_CONNECTED_INFO') === null) {
codeUser = 0;
}else{
//  console.log(parseInt(JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'))[0].code));
  codeUser = parseInt(JSON.parse(localStorage.getItem('USER_CONNECTED_INFO'))[0].code);
}

export enum UserRole {
  // Editor,
  Admin,
  Comptable,
  GestionnaireDepot,
  userGranted = codeUser,
}
