import {environment} from 'src/environments/environment';
import {UserRole} from '../shared/auth.roles';

const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}
// console.log( UserRole.userGranted);
let data: IMenuItem[];

// Menu de profil admin
if ( UserRole.userGranted === 1) {
   data = [
    {
      icon: 'iconsminds-bucket',
      label: 'menu.blank-page',
      to: `${adminRoot}/blank-page`,
    },
    {
      icon: 'iconsminds-satelite-2',
      label: 'menu.sales',
      to: `${adminRoot}/ventes`,
      subs: [
        {
          icon: 'simple-icon-user',
          label: 'menu.clients',
          to: `${adminRoot}/ventes/clients`,
        },

        {
          icon: 'simple-icon-action-undo',
          label: 'menu.retourEmballage',
          to: `${adminRoot}/ventes/retourEmballage`,
        },
        {
          icon: 'iconsminds-record',
          label: 'menu.orders',
          to: `${adminRoot}/ventes/commandes`,
        },
        {
          icon: 'iconsminds-billing',
          label: 'menu.dailyreports',
          to: `${adminRoot}/ventes/rapportjournalier`,
        },
        {
          icon: 'iconsminds-safe-box',
          label: 'menu.crates',
          to: `${adminRoot}/admin/caisse`,
        },
        {
          icon: 'iconsminds-mail-money',
          label: 'menu.margins',
          to: `${adminRoot}/ventes/marges`,
        },
       /* {
          icon: 'iconsminds-arrow-junction',
          label: 'menu.cashAudits',
          to: `${adminRoot}/admin/auditcaisse`,
        },
        {
          icon: 'iconsminds-time-backup',
          label: 'menu.history',
          to: `${adminRoot}/admin/historiqueconnexion`,
        },*/
      ],
    },
    {
      icon: 'iconsminds-coins',
      label: 'menu.finances',
      to: `${adminRoot}/finances`,
      subs: [
        {
          icon: 'iconsminds-coins',
          label: 'menu.depenses',
          to: `${adminRoot}/finances/depenses`,
        },
        {
          icon: 'simple-icon-user',
          label: 'menu.categoriedepenses',
          to: `${adminRoot}/finances/categoriedepenses`,
        },
        ]
    },
    {
       icon: 'iconsminds-puzzle',
       label: 'menu.stocks',
       to: `${adminRoot}/stocks`,
       subs: [
         {
           icon: 'iconsminds-delicious',
           label: 'menu.delivrer',
           to: `${adminRoot}/stocks/fournisseurs`,
         },
         {
           icon: 'iconsminds-can',
           label: 'menu.drinks',
           to: `${adminRoot}/stocks/boissons`,
         },
         {
           icon: 'iconsminds-atom',
           label: 'menu.products',
           to: `${adminRoot}/stocks/produits`,
         },
         {
           icon: 'iconsminds-wallet',
           label: 'menu.price',
           to: `${adminRoot}/stocks/prix`,
         },
         {
           icon: 'iconsminds-receipt-4',
           label: 'menu.supply',
           to: `${adminRoot}/stocks/approvisionnement`,
         },
         {
           icon: 'simple-icon-info',
           label: 'menu.inventories',
           to: `${adminRoot}/stocks/inventaire`,
         },
         {
           icon: 'iconsminds-arrow-inside-45',
           label: 'menu.destocking',
           to: `${adminRoot}/stocks/destockage`,
         },
         {
           icon: 'iconsminds-colosseum',
           label: 'menu.loss',
           to: `${adminRoot}/stocks/perte`,
         },
         {

           icon: 'iconsminds-data-cloud',
           label: 'menu.boncommande',
           to: `${adminRoot}/stocks/boncommande`},
         {
           icon: 'iconsminds-double-circle',
           label: 'menu.etatStock',
           to: `${adminRoot}/stocks/etatStock`,

         },
       ],
     },
    {
       icon: 'simple-icon-settings',
       label: 'menu.settings',
       to: `${adminRoot}/settings`,
       subs: [
         {
           icon: 'iconsminds-home',
           label: 'menu.warehouse',
           to: `${adminRoot}/settings/entrepots`,
         },
         {
           icon: 'iconsminds-home-4',
           label: 'menu.deposit',
           to: `${adminRoot}/settings/depots`,
         },
         {
           icon: 'iconsminds-key-lock',
           label: 'menu.typeusers',
           to: `${adminRoot}/settings/typeusers`,
         },
         {
           icon: 'iconsminds-male-female',
           label: 'menu.users',
           to: `${adminRoot}/settings/users`,
         },
         {
           icon: 'iconsminds-cursor-move-2',
           label: 'menu.typecasier',
           to: `${adminRoot}/stocks/typecaisers`,
         },
         {
           icon: 'iconsminds-pricing',
           label: 'menu.typeprice',
           to: `${adminRoot}/stocks/typeprix`,
         },
        /* {
           icon: 'iconsminds-upward',
           label: 'menu.typeventes',
           to: `${adminRoot}/stocks/typevente`,
         },*/
         {
           icon: 'simple-icon-tag',
           label: 'menu.tagproduit',
           to: `${adminRoot}/stocks/tagproduit`,
         },

       ],
     },
  ];
}

// Menu du profil Gestionnaire Principal
if ( UserRole.userGranted === 2) {
}


// Menu du profil Comptable
if ( UserRole.userGranted === 3) {
}

// Menu du profil Gestionnaire de depot
if ( UserRole.userGranted === 4) {
  data = [
    {
      icon: 'iconsminds-bucket',
      label: 'menu.blank-page',
      to: `${adminRoot}/blank-page`,
    },
    {
      icon: 'iconsminds-satelite-2',
      label: 'menu.sales',
      to: `${adminRoot}/ventes`,
      subs: [
        {
          icon: 'simple-icon-user',
          label: 'menu.clients',
          to: `${adminRoot}/ventes/clients`,
        },

        {
          icon: 'simple-icon-action-undo',
          label: 'menu.retourEmballage',
          to: `${adminRoot}/ventes/retourEmballage`,
        },
        {
          icon: 'iconsminds-record',
          label: 'menu.orders',
          to: `${adminRoot}/ventes/commandes`,
        },
      ],
    },
/*    {
      icon: 'iconsminds-coins',
      label: 'menu.finances',
      to: `${adminRoot}/finances/depenses`,
      subs: [
        {
          icon: 'iconsminds-coins',
          label: 'menu.depenses',
          to: `${adminRoot}/finances/depenses`,
        },
        {
          icon: 'simple-icon-user',
          label: 'menu.categoriedepenses',
          to: `${adminRoot}/finances/categoriedepenses`,
        },
      ]
    },*/
    {
      icon: 'iconsminds-puzzle',
      label: 'menu.stocks',
      to: `${adminRoot}/stocks`,
      subs: [
        {
          icon: 'iconsminds-home-4',
          label: 'menu.deposit',
          to: `${adminRoot}/settings/depots`,
        },
        {
          icon: 'iconsminds-atom',
          label: 'menu.products',
          to: `${adminRoot}/stocks/produits`,
        },
        {
          icon: 'iconsminds-wallet',
          label: 'menu.price',
          to: `${adminRoot}/stocks/prix`,
        },
        {
          icon: 'iconsminds-colosseum',
          label: 'menu.loss',
          to: `${adminRoot}/stocks/perte`,
        },
        {
          icon: 'iconsminds-colosseum',
          label: 'menu.boncommande',
          to: `${adminRoot}/stocks/boncommande`,
        },
      ],
    },
  ];
}

// Menu du profil de la caissiere
if ( UserRole.userGranted === 5) {
  data = [
    {
      icon: 'iconsminds-bucket',
      label: 'menu.blank-page',
      to: `${adminRoot}/blank-page`,
    },
    {
      icon: 'iconsminds-satelite-2',
      label: 'menu.sales',
      to: `${adminRoot}/ventes`,
      subs: [
        {
          icon: 'simple-icon-user',
          label: 'menu.clients',
          to: `${adminRoot}/ventes/clients`,
        },
        {
          icon: 'iconsminds-safe-box',
          label: 'menu.crates',
          to: `${adminRoot}/admin/caisse`,
        },
      ],
    },
    {
          icon: 'iconsminds-coins',
          label: 'menu.finances',
          to: `${adminRoot}/finances`,
          subs: [
            {
              icon: 'iconsminds-coins',
              label: 'menu.depenses',
              to: `${adminRoot}/finances/depenses`,
            },
            {
              icon: 'iconsminds-billing',
              label: 'menu.dailyreports',
              to: `${adminRoot}/ventes/rapportjournalier`,
            },
          ]
        },
  ];
}

export default data;
