
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  // devApiUrl: 'https://solibra.tiadistribution.com/API_REST_TiaDistribution/',
  // devApiUrl: 'https://spiritueux.tiadistribution.com/API_REST_TiaDistribution_Spiritueux/',
  // devApiUrl: 'http://localhost/TerebeiProjects/API_REST_TiaDistribution/',
  // devApiUrl: 'http://localhost:8888/SyabeProjects/API_REST_TiaDistribution/',
  // devApiUrl: 'http://localhost/TerebeiProject/API_REST_TiaDistribution/',
  devApiUrl: 'https://tiadistribution.syabe-tech.com/API_REST_TiaDistribution/',

  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss',
  'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot',
  'light.redruby', 'light.purplemonster'

  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss',
  'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot',
  'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: 'AIzaSyCqoNLB_jTw4nncO12qR-eDH9gAeWiZVaw',
    authDomain: 'vien-angular-login.firebaseapp.com',
    databaseURL: 'https://vien-angular-login.firebaseio.com',
    projectId: 'vien-angular-login',
    storageBucket: 'vien-angular-login.appspot.com',
    messagingSenderId: '16217062888',
    appId: '1:16217062888:web:6b08232ca0c9662fedb85d',
    measurementId: 'G-8ETT79WRRN'
  },
  actionType: {
    CONNECT_ACTION: 'Connect',
    DELETEBY_ACTION: 'DeleteById',
    DELETE_ACTION: 'Delete',
    DISCONNECT_ACTION: 'Disconnect',
    FILTRE_ACTION: 'Filtre',
    FIND_ACTION: 'Retrouver',
    INSERT_ACTION: 'Insert',
    LISTALLBY_ACTION: 'ListAllBy',
    LISTALL_ACTION: 'ListAll',
    LISTBY_ACTION: 'ListBy',
    LIST_ACTION: 'List',
    LOADALL_ACTION: 'LoadAll',
    LOAD_ACTION: 'Load',
    SEARCH_ACTION: 'Rechercher',
    SELECTALLBY_ACTION: 'SelectAllBy',
    SELECTALLDATABY_ACTION: 'SelectAllDataBy',
    SELECTALLDATA_ACTION: 'SelectAllData',
    SELECTALL_ACTION: 'SelectAll',
    SELECTBY_ACTION: 'SelectById',
    SELECTOBY_ACTION: 'SelectBy',
    SELECT_ACTION: 'Select',
    UPDATEBY_ACTION: 'UpdateById',
    UPDATE_ACTION: 'Update',
    UPDATEBYID_ACTION: 'UpdateBy',
    ADD_ACTION: 'Add',
    // USER_CONNECTED_INFO: {} = typeof(localStorage.getItem('USER_CONNECTED_INFO')) !== 'undefined' ? JSON.parse(localStorage.getItem('USER_CONNECTED_INFO')) : {},
  }
};
