import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {from, Observable, throwError} from 'rxjs';
import { getUserRole } from 'src/app/utils/util';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, retry} from 'rxjs/operators';

export interface ISignInCredentials {
  email: string;
  password: string;
  pseudo: string ;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  apiFile = 'controllers/coAccess.php';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  constructor(private auth: AngularFireAuth, private http: HttpClient) {
    this.headers.append('Access-Control-Allow-Origin', '*');
  }

  // tslint:disable-next-line:typedef
  signIn(credentials: ISignInCredentials) {
    return this.auth
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(({ user }) => {
        return user;
      });
  }
  userConnection = (LoginForm: any): Observable<any> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${environment.actionType.CONNECT_ACTION}`;
    const FORMDATA: any = new FormData();
    FORMDATA.append('pseudo', LoginForm.email);
    FORMDATA.append('password', LoginForm.password);
    return this.http.post<any>( url, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }

    signOut = () => from(this.auth.signOut());

  // tslint:disable-next-line:typedef
  register(credentials: ICreateCredentials) {
    return this.auth
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(async ({ user }) => {
        user.updateProfile({
          displayName: credentials.displayName,
        });
        this.auth.updateCurrentUser(user);
        return user;
      });
  }

  // tslint:disable-next-line:typedef
  sendPasswordEmail(email) {
    return this.auth.sendPasswordResetEmail(email).then(() => {
      return true;
    });
  }

  // tslint:disable-next-line:typedef
  resetPassword(credentials: IPasswordReset) {
    return this.auth
      .confirmPasswordReset(credentials.code, credentials.newPassword)
      .then((data) => {
        return data;
      });
  }

  // tslint:disable-next-line:typedef
  async getUser() {
    const u = await this.auth.currentUser;
    return { ...u, role: getUserRole() };
  }
}
